import { StartupBG } from "../../images/Services/Backgrounds"

import Idea from "../../images/Services/Startups/idea.png"

const dataForStartups = {
  service: "startups",
  header: {
    title: "Startup Consultancy",
    description:
      "OSSystem works as an IT consulting company helping to solve various problems that may arise when a new project starts up or when improving an existing one. For small businesses, IT consulting services can trigger successful company expansion.",
    background: StartupBG,
  },
  accents: [
    {
      image: Idea,
      articles: [
        "<strong>Deliver an MVP</strong> quickly and efficiently",
        "<strong>Help the team</strong> stay focused on product and delivery dates by covering routine coding tasks",
        "<strong>Provide an extra pair of hands</strong> for day-to-day IT operations",
        "Cloud enable existing operations, including database and storage <strong>migration to the cloud</strong>",
        "<strong>Design, build</strong> and <strong>support</strong> mobile apps & Web apps",
      ],
    },
  ],
  descriptions: [
    "We want to be your Trusted Technology Advisor, letting you focus on creativity while we take on implementation",
    "We work collaboratively with startup companies, applying our expertise and offering guidance in all aspects of building a business - from formulating strategy and developing business models, to crafting value propositions and optimizing pricing, to digitally transforming a business, to name a few. Our work is influencing the strategies, products, and success stories of startups across a wide range of industries and countries.",
  ],
  projects: [
    {
      id: "210",
    },
    {
      id: "230",
    },
  ],
}

export default dataForStartups
