import React from "react"

const StartupsDescription = ({ descriptions }) => {
  return (
    <section>
      <div className="startup-descriptions">
        <div className="container">
          <div className="row">
            {descriptions.map((description, i) => (
              <div
                key={i}
                className="startup-descriptions-list col-lg-6 col-md-6 col-sm-6 col-xs-12"
              >
                <article className="startup-descriptions-list_item">
                  <p>{description}</p>
                </article>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default StartupsDescription
