import React from "react"

import "../StartupsRoadmap.scss"

const StartupsAccents = ({ accents }) => {
  return (
    <div className="container">
      {accents.map((accent, index) => (
        <div key={index} className="startup-accents row">
          {accent.articles.map((article, idx) => (
            <span
              key={idx}
              className="startup-accents_accent"
              dangerouslySetInnerHTML={{ __html: article }}
            />
          ))}
          <div key={index} className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <img
              src={accent.image}
              className="startup-accents_img"
              alt="idea"
            />
          </div>
        </div>
      ))}
    </div>
  )
}

export default StartupsAccents
