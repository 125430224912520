import React from "react"
import SEO from "../../components/CommonComponents/SEO"
import Layout from "../../components/CommonComponents/Layout"
import HeaderSection from "../../components/Services/ServiceHeader"
import "../../components/SingleService/Startups/StartupsRoadmap.scss"
import StartupsAccents from "../../components/SingleService/Startups/StartupAccents"
import StartupsDescription from "../../components/SingleService/Startups/StartupsDescription"
import LatestProjects from "../../components/UiUxDesign/uiUxLatestProject"
import WorkTogetherSection from "../../components/Services/WorkTogetherSection"
import dataForStartups from "../../information/Services/dataForStartups"
import { mainUrl } from "../../js/config"


export default function Startups() {
  const { header, accents, descriptions } = dataForStartups
  return (
    <section className="startup">
      <SEO
        title="Consulting services for Startup"
        description="If you are starting your own business, you may need startup consultation. At OSsystem, you will get the best roadmap to success with our team of consultants."
        canonical={`${mainUrl}/services/startups/`}
      />
      <Layout showFormPoint={50}>
        <HeaderSection title={header.title} BG={header.background} />
        <StartupsAccents accents={accents} />
        <StartupsDescription descriptions={descriptions} />
        <LatestProjects />
        <WorkTogetherSection />
      </Layout>
    </section>
  )
}
